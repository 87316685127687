import React from "react"
import { StaticImage } from "gatsby-plugin-image"
export default function Example() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-orange-600 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:left-20">
            <div className="lg:self-center">
              <h1 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Le cabinet CÉOS Avocats</span>
              </h1>
              <p className="mt-4 text-lg leading-6 text-orange-200 text-justify">
                Héritier du cabinet Vanhaecke & Bentz, le cabinet Céos Avocats a pris son envol au début de l’année 2021 sur de solides bases d’implantation à LYON depuis 20 ans et à NANTES depuis 10 ans.
              </p>
              <p className="mt-4 text-lg leading-6 text-orange-200 text-justify">
                Rencontre de 3 avocats animés d’une même vision de leur exercice professionnel, cette société interbarreaux (LYON/NANTES) réunit en son sein une équipe de professionnels qui partagent des valeurs communes pour déployer son activité et mettre ses compétences au service de ceux qui recourent à ses interventions.
              </p>
              <p className="mt-4 text-lg leading-6 text-orange-200 text-justify">
                En Rhône Alpes, ses bureaux sont implantés au Palais d’Hiver 149 boulevard de Stalingrad à Villeurbanne (69100).
              </p>
              <p className="mt-4 text-lg leading-6 text-orange-200 text-justify">
                Dans le Grand Ouest, le cabinet Céos Avocats a installé ses bureaux depuis 2 ans au 25 boulevard des Martyrs Nantais de la Résistance à NANTES (44200).
              </p>
              <a
                href="/associes/"
                className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-orange-600 hover:bg-orange-50"
              >
                Découvrez les associés
              </a>
            </div>
          </div>
          <div className="-mt-12 aspect-h-3 md:aspect-h-1 text-center">
            <StaticImage
              className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-1 lg:translate-y-20"
              src="../../images/ceos-lyon-nantes.jpg"
              alt="Ceos Bureau"
              width={490}
              height={490}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
