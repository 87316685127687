import React from "react"

export default function Example() {
    return (
      <div className="bg-gray-50 pt-12 sm:pt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Nos activités
            </h2>
            <p className="mt-3 text-xl text-gray-500 sm:mt-4">
            Le cabinet Céos Avocats a fait du Droit Social, et plus particulièrement de la Sécurité et de la Santé au travail, son cœur d’expertise juridique. 
            </p>
            <p className="mt-3 text-xl text-gray-500 sm:mt-4">
            Nous assistons notamment des grands groupes du BTP dans la mise en œuvre de la législation sur la santé et la sécurité au travail, et, dans son corollaire, la gestion des cotisations de sécurité sociale. 
            </p>
          </div>
        </div>
        <div className="mt-10 pb-12 bg-white sm:pb-16">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-gray-50" />
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-4xl mx-auto">
                <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                  <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                    <dd className="order-1 text-2xl font-extrabold text-orange-600"><a href="/activites/#droit"><br />Droit social</a></dd>
                  </div>
                  <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                    <dd className="order-1 text-2xl font-extrabold text-orange-600"><a href="/activites/#contentieux">Contentieux judiciaire et administratif</a></dd>
                  </div>
                  <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                    <dd className="order-1 text-2xl font-extrabold text-orange-600"><a href="/activites/#formation"><br />Formation</a></dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  